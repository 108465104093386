import React from 'react'
import Lottie from 'react-lottie'

import * as styles from './styles.module.scss'

import vibrant from '../../assets/images/svg/investment/vibrant.png'
import acorns from '../../assets/images/svg/investment/acorns.png'
import iphone12 from '../../assets/images/svg/investment/iphone12.png'
import start from '../../assets/images/svg/investment/Star.png'
import overview from '../../assets/images/svg/investment/overview.png'
import circle from '../../assets/images/svg/investment/circle.png'
import square from '../../assets/images/svg/investment/squar.png'
import info from '../../assets/images/svg/investment/info.png'
import pero from '../../assets/images/svg/investment/pero.png'
import mobilePhoneBlock from '../../assets/images/svg/investment/mobilePhoneBlock.jpg'
import device from '../../assets/images/svg/investment/device.png'
import uxUi from '../../assets/images/svg/investment/uxUi.jpg'
import onboarding from '../../assets/images/svg/investment/onboarding.jpg'
import phone1 from '../../assets/images/svg/investment/phone1.png'
import phone2 from '../../assets/images/svg/investment/phone2.png'
import phone3 from '../../assets/images/svg/investment/phone3.png'
import featurePhone1 from '../../assets/images/svg/investment/featurePhone1.png'
import featurePhone2 from '../../assets/images/svg/investment/featurePhone2.png'
import featurePhone3 from '../../assets/images/svg/investment/featurePhone3.png'
import featurePhone4 from '../../assets/images/svg/investment/featurePhone4.png'
import phoneMobile1 from '../../assets/images/svg/investment/phoneMobile1.png'
import phoneMobile2 from '../../assets/images/svg/investment/phoneMobile2.png'
import phoneMobile3 from '../../assets/images/svg/investment/phoneMobile3.png'
import phoneMobile4 from '../../assets/images/svg/investment/phoneMobile4.png'
import iphoneXr from '../../assets/images/svg/investment/iphoneXr.jpg'
import mobileIphoneXR from '../../assets/images/svg/investment/mobileIphoneXR.jpg'
import chats from '../../assets/images/svg/investment/chats.png'
import support from '../../assets/images/svg/investment/support.png'
import thanksForWatching from '../../assets/images/svg/investment/thanksForWatching.png'
import phoneInHand from '../../assets/images/svg/investment/phoneInHand.png'
import personalData from '../../assets/images/svg/investment/personalData.png'
import shadow from '../../assets/images/svg/investment/shadow.svg'
import chatsMobile from '../../assets/images/svg/investment/chatsMobile.png'
import supportMobile from '../../assets/images/svg/investment/supportMobile.png'
import animation from '../../assets/images/svg/investment/animation.json'

const InvestmentComponent = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (

    <div className={styles.main} style={{ backgroundImage: `url(${vibrant})` }}>
      <div className={styles.containerPage}>
          <div
            className={styles.containerPageBlock1}
          >
            <div className={styles.containerPageHeader}>
              <div>App design</div>
              <div>2021</div>
            </div>
            <div className={styles.containerPageFlex}>
              <div className={styles.containerPageFlexTitle}>
                <img src={acorns} alt='' />
                <span>Investing is easy</span>
              </div>
              <div className={styles.containerPageFlexIcon}>
                <img src={iphone12} alt='' />
                <img src={shadow} alt='' />
              </div>
            </div>
          </div>
          <div className={styles.containerPageBlock2}>
            <div className={styles.containerPageBlock2Item}>
              <div className={styles.containerPageBlock2ItemStar1}>
                <img src={start} alt='' />
              </div>
              <div className={styles.containerPageBlock2ItemOverview}>
                <img src={overview} alt='' />
              </div>
              <div className={styles.containerPageBlock2ItemStar2}>
                <img src={start} alt='' />
              </div>
            </div>
            <div className={styles.containerPageBlock2Item}>
              <span>
                It is your assistant on the path to wealth. The pandemic has
                taught people to save and invest. ACORNS will help you multiply
                your savings. A product with a user-friendly interface that can
                be understood by any user, even those who are far from finance
                and investments. It’s simple, register, undergo training and
                make investments at the level of experienced investors.
              </span>
            </div>
          </div>
        <div>
          <h1>Scope of work</h1>
          <div className={styles.scopeOfWork}>
            <div className={styles.scopeOfWorkRelative}>
              <div className={styles.scopeOfWorkItem}>
                <img src={circle} alt='' />
                <span>Research</span>
              </div>
              <div className={styles.scopeOfWorkAbsolut1}></div>
              <div className={styles.scopeOfWorkAbsolut2}></div>
            </div>
            <div className={styles.scopeOfWorkRelative}>
              <div className={styles.scopeOfWorkItem}>
                <img src={square} alt='' />
                <span>User flow</span>
              </div>
              <div className={styles.scopeOfWorkAbsolut1}></div>
              <div className={styles.scopeOfWorkAbsolut2}></div>
            </div>
            <div className={styles.scopeOfWorkRelative}>
              <div className={styles.scopeOfWorkItem}>
                <img src={info} alt='' />
                <span>Wireframes</span>
              </div>
              <div className={styles.scopeOfWorkAbsolut1}></div>
              <div className={styles.scopeOfWorkAbsolut2}></div>
            </div>
            <div className={styles.scopeOfWorkRelative}>
              <div className={styles.scopeOfWorkItem}>
                <img src={pero} alt='' />
                <span>UX/UI Design</span>
              </div>
              <div className={styles.scopeOfWorkAbsolut1}></div>
              <div className={styles.scopeOfWorkAbsolut2}></div>
            </div>
          </div>
        </div>
          <div className={styles.userFlow}>
            <h1>
              User flow
              <br />& Wireframes
            </h1>
            <div className={styles.userFlowItems}>
              <div className={styles.userFlowItemsSection}>
                <div className={styles.userFlowItemsItem}>Onboarding</div>
              </div>
              <div className={styles.userFlowItemsSection}>
                <div className={styles.userFlowItemsItem}>Home</div>
                <div className={styles.userFlowItemsEmpty}>Replenishment</div>
                <div className={styles.userFlowItemsEmpty}>Operations</div>
                <div className={styles.userFlowItemsEmpty}>Favourites</div>
                <div className={styles.userFlowItemsEmpty}>All assets</div>
              </div>
              <div className={styles.userFlowItemsSection}>
                <div className={styles.userFlowItemsItem}>Market</div>
                <div className={styles.userFlowItemsEmpty}>
                  Viewing an asset
                </div>
                <div className={styles.userFlowItemsEmpty}>Purchase</div>
                <div className={styles.userFlowItemsEmpty}>Sale</div>
              </div>
              <div className={styles.userFlowItemsSection}>
                <div className={styles.userFlowItemsItem}>Chat</div>
                <div className={styles.userFlowItemsEmpty}>Dialogue</div>
              </div>
              <div className={styles.userFlowItemsSection}>
                <div className={styles.userFlowItemsItem}>Setting</div>
                <div className={styles.userFlowItemsEmpty}>Personal data</div>
                <div className={styles.userFlowItemsEmpty}>E-statement</div>
                <div className={styles.userFlowItemsEmpty}>Refferal code</div>
                <div className={styles.userFlowItemsEmpty}>FAQs</div>
              </div>
            </div>
          </div>
          <div className={styles.morePhoneBlock}>
            <h1>Wireframes</h1>
            <Lottie options={defaultOptions}
                    height={679}
                    width={1400}
            />
          </div>
          <div className={styles.morePhoneBlockMobile}>
            <h1>Wireframes</h1>
            <img src={mobilePhoneBlock} alt='' />
          </div>
          <div className={styles.gridSystem}>
            <div className={styles.gridSystemBlock}>
              <div className={styles.gridSystemBlockText}>
                <div className={styles.gridSystemBlockTextFirstBlock}>
                  <h1>Grid system</h1>
                  <div className={styles.gridSystemBlockTextFirstBlockInfo}>
                    We created a simple but higly functional grid system,
                    following the same approach of the design system.
                  </div>
                  <div className={styles.gridSystemBlockTextFirstBlockSize}>
                    <div
                      className={styles.gridSystemBlockTextFirstBlockSizeBlock}
                    >
                      <span>
                        Colums:{' '}
                        <span
                          className={
                            styles.gridSystemBlockTextFirstBlockSizeBlockColor
                          }
                        >
                          2
                        </span>
                      </span>

                      <span>
                        Gutter:{' '}
                        <span
                          className={
                            styles.gridSystemBlockTextFirstBlockSizeBlockColor
                          }
                        >
                          15px
                        </span>
                      </span>
                    </div>
                    <div
                      className={styles.gridSystemBlockTextFirstBlockSizeBlock}
                    >
                      <span>
                        Margin:{' '}
                        <span
                          className={
                            styles.gridSystemBlockTextFirstBlockSizeBlockColor
                          }
                        >
                          24px
                        </span>
                      </span>
                      <span>
                        Width:{' '}
                        <span
                          className={
                            styles.gridSystemBlockTextFirstBlockSizeBlockColor
                          }
                        >
                          156px
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.gridSystemBlockDevice}>
                <img src={device} alt='' />
              </div>
            </div>
            <div className={styles.gridSystemThief1}></div>
            <div className={styles.gridSystemThief2}></div>
          </div>
          <div className={styles.uxUi}>
            <h1>UX/UI Design</h1>
            <img src={uxUi} alt='' />
          </div>
          <h1 className={styles.onboardingTitle}>Onboarding</h1>
          <div
            className={styles.onboarding}
            style={{ backgroundImage: `url(${onboarding})` }}
          >
            <div className={styles.onboardingPhone1}>
              <img src={phone1} alt='' />
            </div>
            <div className={styles.onboardingPhone2}>
              <img src={phone2} alt='' />
            </div>
            <div className={styles.onboardingPhone3}>
              <img src={phone3} alt='' />
            </div>
          </div>
          <div className={styles.keyFeature}>
            <span>The result</span>
            <h1>Some of the key features</h1>
            <div className={styles.keyFeatureBlock}>
              <div className={styles.keyFeatureBlockText}>
                <div className={styles.keyFeatureBlockTextTitle}>
                  Main screen
                </div>
                <div className={styles.keyFeatureBlockTextInfo}>
                  The main screen displays all the information that will allow
                  you to draw a conclusion about the current status of your
                  investment account in 40 seconds. The screen has no extra
                  icons. All icons will guide you to the information you need
                  and want.
                </div>
              </div>
              <div className={styles.keyFeatureBlockImage}>
                <img src={featurePhone1} alt='' />
              </div>
            </div>
            <div className={styles.keyFeatureBlock}>
              <div className={styles.keyFeatureBlockImage}>
                <img src={featurePhone2} alt='' />
              </div>
              <div className={styles.keyFeatureBlockText}>
                <div className={styles.keyFeatureBlockTextTitle}>Market</div>
                <div className={styles.keyFeatureBlockTextInfo}>
                  The market is divided into sections to make it easier to
                  navigate where the most profit was made. Investments,
                  currency, funds, futures, your investments, status of received
                  income.
                </div>
              </div>
            </div>
            <div className={styles.keyFeatureBlock}>
              <div className={styles.keyFeatureBlockText}>
                <div className={styles.keyFeatureBlockTextTitle}>Setting</div>
                <div className={styles.keyFeatureBlockTextInfo}>
                  Simple settings without unnecessary fields. Quick access to legal documents, as well as to the
                  community
                  once again confirms the simplicity and efficiency of the ACORNS
                </div>
              </div>
              <div className={styles.keyFeatureBlockImage}>
                <img src={personalData} alt='' />
              </div>
            </div>
          </div>
          <div
            className={`${styles.keyFeatureBlock} ${styles.keyFeatureWidth}`}
          >
            <div
              className={`${styles.keyFeatureBlockText} ${styles.keyFeatureCustom}`}
            >
              <div className={styles.keyFeatureBlockTextTitle}>Setting</div>
              <div className={styles.keyFeatureBlockTextInfo}>
                Simple settings without unnecessary fields. Quick access to
                legal documents, as well as to the community once again confirms
                the simplicity and efficiency of the ACORNS
              </div>
            </div>
            <div className={styles.keyFeatureBlockImage}>
              <img src={featurePhone3} alt='' />
              <img src={featurePhone4} alt='' />
            </div>
          </div>
          <div className={styles.keyFeatureMobile}>
            <span>The result</span>
            <h1>Some of the key features</h1>
            <div className={styles.keyFeatureMobileBlock}>
              <div className={styles.keyFeatureMobileBlockText}>
                <div className={styles.keyFeatureMobileBlockTextTitle}>
                  Main screen
                </div>
                <div className={styles.keyFeatureMobileBlockTextInfo}>
                  The main screen displays all the information that will allow
                  you to draw a conclusion about the current status of your
                  investment account in 40 seconds. The screen has no extra
                  icons. All icons will guide you to the information you need
                  and want.
                </div>
              </div>
              <div className={styles.keyFeatureMobileBlockImage}>
                <img src={phoneMobile1} alt='' />
              </div>
            </div>
            <div className={styles.keyFeatureMobileBlock}>
              <div className={styles.keyFeatureMobileBlockText}>
                <div className={styles.keyFeatureMobileBlockTextTitle}>Market</div>
                <div className={styles.keyFeatureMobileBlockTextInfo}>
                  The market is divided into sections to make it easier to
                  navigate where the most profit was made. Investments,
                  currency, funds, futures, your investments, status of received
                  income.
                </div>
              </div>
              <div className={styles.keyFeatureMobileBlockImage}>
                <img src={phoneMobile2} alt='' />
              </div>
            </div>
            <div className={styles.keyFeatureMobileBlock}>
              <div className={styles.keyFeatureMobileBlockText}>
                <div className={styles.keyFeatureMobileBlockTextTitle}>
                  Setting
                </div>
                <div className={styles.keyFeatureMobileBlockTextInfo}>
                  Simple settings without unnecessary fields. Quick access to legal documents, as well as to the
                  community
                  once again confirms the simplicity and efficiency of the ACORNS
                </div>
              </div>
              <div className={styles.keyFeatureMobileBlockImageCustom}>
                <img src={phoneMobile3} alt='' />
                <img src={phoneMobile4} alt='' />
              </div>
            </div>
          </div>
          <div className={styles.morePhoneBlock}>
            <img src={iphoneXr} alt='' />
          </div>
          <div className={styles.morePhoneBlockMobile}>
            <img src={mobileIphoneXR} alt='' />
          </div>
          <div className={`${styles.phoneBlockView} ${styles.marginUnset}`}>
            <div className={styles.phoneBlockViewBlock}>
              <div className={styles.phoneBlockViewBlockImage}>
                <img src={chats} alt='' />
              </div>
              <div className={styles.phoneBlockViewBlockText}>
                <div className={styles.phoneBlockViewBlockTextTitle}>Chats</div>
                <div className={styles.phoneBlockViewBlockTextInfo}>
                  Chat with support or have a chat with a community of
                  like-minded people with a stylish and simple chat.
                  User-friendly interface does not distract from the intended
                  goal.
                </div>
              </div>
            </div>
            <div className={styles.phoneBlockViewBlock}>
              <div className={styles.phoneBlockViewBlockText}>
                <div className={styles.phoneBlockViewBlockTextTitle}>Support</div>
                <div className={styles.phoneBlockViewBlockTextInfo}>
                  An application in which everything is simple and perfect. The
                  support responds quickly, structured and to the point. Get the
                  most out of ACORNS.
                </div>
              </div>
              <div className={styles.phoneBlockViewBlockImage}>
                <img src={support} alt='' />
              </div>
            </div>
            {/*  */}
          </div>
          <div className={styles.phoneBlockViewMobile}>
            <span>The result</span>
            <h1>Some of the key features</h1>
            <div className={styles.phoneBlockViewMobileBlock}>
              <div className={styles.phoneBlockViewMobileBlockText}>
                <div className={styles.phoneBlockViewMobileBlockTextTitle}>
                  Chats
                </div>
                <div className={styles.phoneBlockViewMobileBlockTextInfo}>
                  Chat with support or have a chat with a community of like-minded people with a stylish and simple
                  chat. User-friendly interface does not distract from the intended goal.
                </div>
              </div>
              <div className={styles.phoneBlockViewMobileBlockImage}>
                <img src={chatsMobile} alt='' />
              </div>
            </div>
            <div className={styles.phoneBlockViewMobileBlock}>
              <div className={styles.phoneBlockViewMobileBlockText}>
                <div className={styles.phoneBlockViewMobileBlockTextTitle}>Support</div>
                <div className={styles.phoneBlockViewMobileBlockTextInfo}>
                  An application in which everything is simple and perfect. The support responds quickly, structured and
                  to the point. Get the most out of ACORNS.
                </div>
              </div>
              <div className={styles.phoneBlockViewMobileBlockImage}>
                <img src={supportMobile} alt='' />
              </div>
            </div>
          </div>
          <div className={styles.thanksForWatching}>
            <div className={styles.thanksForWatchingTitle}>
              thanks
              <br />
              for
              <br />
              watching
              <img
                className={styles.thanksForWatchingTitleThief1}
                src={thanksForWatching}
                alt=''
              />
              <img
                className={styles.thanksForWatchingTitleThief2}
                src={thanksForWatching}
                alt=''
              />
            </div>
            <div className={styles.thanksForWatchingImage}>
              <img src={phoneInHand} alt='' />
            </div>
          </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerText}>
          All materials were used for non-commercial purposes and belong to
          its owners.
        </div>
      </div>
    </div>
  )
}
export default InvestmentComponent