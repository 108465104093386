// extracted by mini-css-extract-plugin
export var main = "styles-module--main--7JhP8";
export var containerPage = "styles-module--containerPage--qoz9F";
export var containerPageContent = "styles-module--containerPage-content--3kW6R";
export var containerPageHeader = "styles-module--containerPage-header--ylw0i";
export var containerPageFlex = "styles-module--containerPage-flex--IWnFa";
export var containerPageFlexTitle = "styles-module--containerPage-flex-title--esYmU";
export var containerPageFlexIcon = "styles-module--containerPage-flex-icon--dkMQU";
export var containerPageBlock1 = "styles-module--containerPage-block1--EEEVP";
export var containerPageBlock2 = "styles-module--containerPage-block2--searR";
export var containerPageBlock2Item = "styles-module--containerPage-block2-item--O8GpR";
export var containerPageBlock2ItemStar1 = "styles-module--containerPage-block2-item-star1--6LrTw";
export var containerPageBlock2ItemOverview = "styles-module--containerPage-block2-item-overview--kOLpo";
export var containerPageBlock2ItemStar2 = "styles-module--containerPage-block2-item-star2--pwc82";
export var scopeOfWork = "styles-module--scopeOfWork--Z4eCi";
export var scopeOfWorkItem = "styles-module--scopeOfWork-item--tODrn";
export var scopeOfWorkAbsolut1 = "styles-module--scopeOfWork-absolut1--wvdUr";
export var scopeOfWorkAbsolut2 = "styles-module--scopeOfWork-absolut2--s57Ka";
export var scopeOfWorkRelative = "styles-module--scopeOfWork-relative--MFQwz";
export var userFlow = "styles-module--userFlow--kU6Dv";
export var userFlowItems = "styles-module--userFlow-items--aN-iD";
export var userFlowItemsSection = "styles-module--userFlow-items-section--Gy2K2";
export var userFlowItemsItem = "styles-module--userFlow-items-item--kXrgJ";
export var userFlowItemsEmpty = "styles-module--userFlow-items-empty--bXvSl";
export var morePhoneBlock = "styles-module--morePhoneBlock--Gp3m8";
export var morePhoneBlockLottie = "styles-module--morePhoneBlock-lottie--XQSSZ";
export var morePhoneBlockMobile = "styles-module--morePhoneBlockMobile--53ePv";
export var gridSystem = "styles-module--gridSystem--FKNiJ";
export var gridSystemBlock = "styles-module--gridSystem-block--QqNqq";
export var gridSystemBlockText = "styles-module--gridSystem-block-text--dXAg-";
export var gridSystemBlockTextFirstBlock = "styles-module--gridSystem-block-text-firstBlock--9Mk3O";
export var gridSystemBlockTextFirstBlockInfo = "styles-module--gridSystem-block-text-firstBlock-info--lMTJA";
export var gridSystemBlockTextFirstBlockSize = "styles-module--gridSystem-block-text-firstBlock-size--TQfDH";
export var gridSystemBlockTextFirstBlockSizeBlock = "styles-module--gridSystem-block-text-firstBlock-size-block--zvd9z";
export var gridSystemBlockTextFirstBlockSizeBlockColor = "styles-module--gridSystem-block-text-firstBlock-size-block-color--9rudc";
export var gridSystemBlockDevice = "styles-module--gridSystem-block-device--CN9ds";
export var gridSystemThief1 = "styles-module--gridSystem-thief1--5oGgy";
export var gridSystemThief2 = "styles-module--gridSystem-thief2--AsFS1";
export var uxUi = "styles-module--uxUi--rXrga";
export var onboarding = "styles-module--onboarding--sKxGn";
export var onboardingPhone1 = "styles-module--onboarding-phone1--T7OD0";
export var onboardingPhone2 = "styles-module--onboarding-phone2--+l3O2";
export var onboardingPhone3 = "styles-module--onboarding-phone3--Ms0A6";
export var keyFeature = "styles-module--keyFeature--0oadV";
export var keyFeatureBlock = "styles-module--keyFeature-block--4lYZZ";
export var keyFeatureBlockText = "styles-module--keyFeature-block-text--nmIk5";
export var keyFeatureBlockTextTitle = "styles-module--keyFeature-block-text-title--MWpWe";
export var keyFeatureBlockTextInfo = "styles-module--keyFeature-block-text-info--KQacf";
export var keyFeatureBlockImage = "styles-module--keyFeature-block-image--WwvE6";
export var keyFeatureBlockImageCustom = "styles-module--keyFeature-block-imageCustom--gRYqd";
export var keyFeatureWidth = "styles-module--keyFeature-width--onHmt";
export var keyFeatureCustom = "styles-module--keyFeature-custom--t1NcT";
export var keyFeatureMobile = "styles-module--keyFeatureMobile--MBGSt";
export var keyFeatureMobileBlock = "styles-module--keyFeatureMobile-block--ez3n7";
export var keyFeatureMobileBlockText = "styles-module--keyFeatureMobile-block-text--lQWDU";
export var keyFeatureMobileBlockTextTitle = "styles-module--keyFeatureMobile-block-text-title--H-khe";
export var keyFeatureMobileBlockTextInfo = "styles-module--keyFeatureMobile-block-text-info--cb3es";
export var keyFeatureMobileBlockImage = "styles-module--keyFeatureMobile-block-image--uJSvv";
export var keyFeatureMobileBlockImageCustom = "styles-module--keyFeatureMobile-block-image-custom--YIVbm";
export var marginUnset = "styles-module--marginUnset--YSQVB";
export var phoneBlockView = "styles-module--phoneBlockView---unhU";
export var phoneBlockViewBlock = "styles-module--phoneBlockView-block--Z-U1v";
export var phoneBlockViewBlockText = "styles-module--phoneBlockView-block-text--YxuGi";
export var phoneBlockViewBlockTextTitle = "styles-module--phoneBlockView-block-text-title--15ayj";
export var phoneBlockViewBlockTextInfo = "styles-module--phoneBlockView-block-text-info--o-VWr";
export var phoneBlockViewBlockImage = "styles-module--phoneBlockView-block-image--My0dF";
export var phoneBlockViewBlockImageCustom = "styles-module--phoneBlockView-block-imageCustom--9hG2M";
export var phoneBlockViewWidth = "styles-module--phoneBlockView-width--VUzBu";
export var phoneBlockViewCustom = "styles-module--phoneBlockView-custom--sy3Qb";
export var phoneBlockViewMobile = "styles-module--phoneBlockViewMobile--Ils3u";
export var phoneBlockViewMobileBlock = "styles-module--phoneBlockViewMobile-block--SCL9a";
export var phoneBlockViewMobileBlockText = "styles-module--phoneBlockViewMobile-block-text--LRiYv";
export var phoneBlockViewMobileBlockTextTitle = "styles-module--phoneBlockViewMobile-block-text-title--Txx0H";
export var phoneBlockViewMobileBlockTextInfo = "styles-module--phoneBlockViewMobile-block-text-info--cFQ-e";
export var phoneBlockViewMobileBlockImage = "styles-module--phoneBlockViewMobile-block-image--Eal93";
export var phoneBlockViewMobileBlockImageCustom = "styles-module--phoneBlockViewMobile-block-image-custom--lGwDu";
export var thanksForWatchingTitle = "styles-module--thanksForWatching-title--trH-H";
export var thanksForWatchingTitleThief1 = "styles-module--thanksForWatching-title-thief1--Q74Ja";
export var thanksForWatchingTitleThief2 = "styles-module--thanksForWatching-title-thief2--upDX0";
export var thanksForWatchingImage = "styles-module--thanksForWatching-image--WatHF";
export var thanksForWatching = "styles-module--thanksForWatching--JaIkv";
export var onboardingTitle = "styles-module--onboardingTitle--loV7-";
export var footer = "styles-module--footer--IyUMV";
export var footerText = "styles-module--footer-text--gRsTY";